import React from "react";

/**
 * Text used in hero section of all pages
 */
export const heroText = {
  heading: "Nasce HCBE Italy",
  text: (
    <>
      Sede secondaria in Italia di Hyundai Capital Bank Europe Gmbh,
      <br /> joint Venture fra Santander Consumer Bank AG e Hyundai Capital
      Services
    </>
  ),
};
