import { Heading, Text } from "@chakra-ui/react";
import React from "react";
import { heroText } from "../../constants/strings";
import Breadcrumb from "../Breadcrumb";
import Hero from "../Hero";

export const SharedHero: React.FC = () => (
  <Hero
    h={"auto"}
    pt={"80px"}
    textColor={"utils.white"}
    breadcrumb={
      <Breadcrumb
        separatorColor={"utils.white"}
        maxW={"container.grid"}
        bg={"accent.01"}
        fontSize={"small"}
        pt={"30px"}
      />
    }
  >
    <Heading as={"h1"} fontSize={38} mb={3}>
      {heroText.heading}
    </Heading>

    <Text fontSize={["12px", "14px"]} maxW={"640px"}>
      {heroText.text}
    </Text>
  </Hero>
);
